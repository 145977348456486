import { CommonModule, DecimalPipe } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, enableProdMode, importProvidersFrom } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router, provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { AuthService, authInterceptor, errorsInterceptor } from '@mca/auth/api';
import { LocalStorageService, StorageService } from '@mca/shared/domain';
import { SharedFeatureDynamicFormModule } from '@mca/shared/feature-dynamic-form';
import { SharedFeatureGridModule } from '@mca/shared/feature-grid';
import { SharedFeatureSmartUiModule } from '@mca/shared/feature-smart-ui';
import { environmentToken } from '@mca/shared/util';
import { serviceWorkerClientUrlToken, ServiceWorkerCommand } from '@mca/shared/util/service-worker';
import { WebsocketBridge, WebsocketRxjsService } from '@mca/system/api';
import * as Sentry from '@sentry/angular-ivy';
import { Amplify } from 'aws-amplify';
import { initialConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';
import { filter, first, of, switchMap } from 'rxjs';
import { AppComponent } from './app/app.component';
import { APP_ROUTES } from './app/app.rotes';
import { MenuService } from './app/layout/header/menu.service';
import { environment } from './environments/environment';
import { generatedServiceWorkerUrl } from './workers/service-worker-config';

if (environment.production) {
  enableProdMode();
}

if (!environment.production && !'sentry is disabled') {
  Sentry.init({
    // dsn: 'https://ffc80b1c5639466aa68a85312a8caacc@o440036.ingest.sentry.io/5407882', // Boris's account
    dsn: 'https://2aed6b4b655b48dfac0a13f2b36a8252@o1317293.ingest.sentry.io/6570386', // Andrii's GFL account
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
    ],
    tracePropagationTargets: ['localhost', environment.apiUrl],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

// service workers only available with https
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(generatedServiceWorkerUrl, { type: 'module' }).then(async () => {
    const worker = await navigator.serviceWorker.ready;
    await worker.update();
    worker.active?.postMessage({ command: ServiceWorkerCommand.CLEAN_CACHE });
  });
}

Amplify.configure({
  Auth: { Cognito: environment.cognitoConfig },
});

export const initMenu = (menuService: MenuService, authService: AuthService) => () =>
  authService.tokenLoaded$.pipe(
    switchMap(awsToken => (awsToken ? menuService.select('loaded').pipe(filter(Boolean)) : of(true))),
    first(),
  );

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      CommonModule,
      FormsModule,
      BrowserModule,
      SharedFeatureSmartUiModule,
      MenubarModule,
      ToastModule,
      SharedFeatureDynamicFormModule,
      // initilize grid providers on top level
      SharedFeatureGridModule,
    ),
    { provide: environmentToken, useValue: environment },
    MessageService,
    DecimalPipe,
    DialogService,
    {
      provide: APP_INITIALIZER,
      useFactory: initMenu,
      deps: [MenuService, AuthService],
      multi: true,
    },
    { provide: WebsocketBridge, useClass: WebsocketRxjsService },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: StorageService,
      useClass: LocalStorageService,
    },
    provideEnvironmentNgxMask({
      thousandSeparator: ',',
      decimalMarker: '.',
      allowNegativeNumbers: true,
      specialCharacters: initialConfig.specialCharacters.filter(v => v !== '-'),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      patterns: { ...initialConfig.patterns, N: { pattern: /[-0-9]/ } },
    }),
    provideAnimations(),
    provideRouter(
      APP_ROUTES,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
        paramsInheritanceStrategy: 'always',
      }),
      withComponentInputBinding(),
      // withDebugTracing(),
    ),
    provideHttpClient(withInterceptors([authInterceptor, errorsInterceptor])),
    {
      provide: serviceWorkerClientUrlToken,
      useValue: generatedServiceWorkerUrl,
    },
  ],
});
