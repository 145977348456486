export enum Venues {
  GFENY = 1,
  SLC = 2,
  LENDORA = 3,
  Lacent = 37,
  WhiteRoad = 38,
  EHC = 133,
  UnitedFirst = 168,
  UnitedFirst_BasePoint = 167,
  WhiteRoad_BasePoint = 169,
}
