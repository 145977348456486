import { Injectable, inject } from '@angular/core';
import { AuthService, HeaderMenuItem, MenuAccessService } from '@mca/auth/api';
import { deepFlatWhenTotalLessThan } from '@mca/shared/util';
import { LiveMessageNew, LiveMessagesService, SystemService } from '@mca/system/api';
import { RxState } from '@rx-angular/state';
import { combineLatest, filter, map, startWith, tap } from 'rxjs';

interface State {
  loaded: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MenuService extends RxState<State> {
  private authService = inject(AuthService);
  private sysService = inject(SystemService);
  private menuAccessService = inject(MenuAccessService);
  private liveMessageService = inject(LiveMessagesService);

  private menuItems: HeaderMenuItem[] = [
    {
      icon: 'portal-logo portal-logo__icon',
      styleClass: 'p-no-submenu-icon me-auto',
      routerLink: ['/'],
      keepNested: true,
    },
    {
      label: 'My Work',
      routerLink: ['workload'],
      permissions: ['menu_workload'],
    },
    {
      label: 'Accounting Dashboard',
      routerLink: ['dashboard', 'accounting'],
      permissions: ['menu_accounting_dashboard'],
    },
    {
      label: 'Underwriting',
      permissions: ['menu_funding_review'],
      items: [
        { label: 'Funding Review', routerLink: ['events', 'underwriter'] },
        { label: 'Docusign status', routerLink: ['events', 'docusign'] },
        {
          label: 'Account Reconciliation',
          routerLink: ['collection/reconciliation'],
        },
      ],
    },
    {
      label: 'MCA',
      permissions: ['menu_mca'],
      pathMatch: 'partial',
      items: [
        { label: 'New MCA', routerLink: ['mcaedit/new'], permissions: ['menu_mca_new'] },
        { label: 'MCA List', routerLink: ['mcalist'] },
        { label: 'Assignments Pool', routerLink: ['assignments'], permissions: ['menu_mca_assignments'] },
      ],
    },
    {
      label: 'Loan',
      permissions: ['menu_loan'],
      items: [
        { label: 'New Loan', routerLink: ['loan/new'], permissions: ['menu_loan_new'] },
        { label: 'Loan List', routerLink: ['loan'], permissions: ['menu_loan_new'] },
        { label: 'Loan Dashboard', routerLink: ['loan/dashboard'], permissions: ['menu_loan_new'] },
        { label: 'Release Coupons', routerLink: ['loan/release-coupons'], permissions: ['menu_loan_release'] },
        { label: 'Issuer Basic View', routerLink: ['loan/issuer'], permissions: ['menu_loan_issuer'] },
        { label: 'Loan Transactions', routerLink: ['loan/transactions'], permissions: ['menu_loan_transactions'] },
      ] as HeaderMenuItem[],
    },
    {
      label: 'PMs',
      styleClass: 'submenu-wide',
      permissions: [
        'menu_pm',
        'menu_pm_funded_in_range',
        'menu_pm_refinancecandidates',
        'menu_pm_pre_approved_offers',
        'menu_pm_collateralized_provider_view',
      ],
      items: [
        { label: 'Summary', routerLink: ['pm/summary'], permissions: ['menu_pm_summary'] },
        {
          label: 'MCA Expenditure Allocations',
          routerLink: ['pm/mcatransactionsummary'],
          permissions: ['menu_pm_mcatransactionsummary'],
        },
        {
          label: 'ISO Statistics',
          permissions: ['menu_pm'],
          items: [
            { label: 'Performance (not working)', routerLink: ['reports/pm/isostat'] },
            { label: 'By MCA', routerLink: ['reports/pm/isostat1'] },
            { label: 'Time Buckets', routerLink: ['reports/pm/isotimebucketreport'] },
            { label: 'ISO rating', routerLink: ['pm/iso-rating'] },
          ],
        },
        { label: 'Payments Expected/Actual', routerLink: ['reports/pm/payments'], permissions: ['menu_pm'] },
        { label: 'MCA Balances', routerLink: ['pm/mcabalances'], permissions: ['menu_pm'] },
        {
          label: 'Refinance Candidates',
          routerLink: ['pm/refinancecandidates'],
          permissions: ['menu_pm_refinancecandidates'],
        },
        { label: 'Business Events...', routerLink: ['pm/businessevents'], permissions: ['menu_pm'] },
        { label: 'Accounting', routerLink: ['events', 'accounting'], permissions: ['menu_pm_accounting'] },
        { label: 'Deal Stat Change', routerLink: ['pm/dealstatuschange'], permissions: ['menu_pm_dealstatuschange'] },
        { label: 'Funded in Range', routerLink: ['pm/funded-in-range'], permissions: ['menu_pm_funded_in_range'] },
        {
          label: 'Portfolio',
          routerLink: ['pm/portfolio'],
          permissions: ['menu_pm_portfolio'],
        },
        {
          label: 'Portfolio Analytics',
          permissions: ['menu_pm_portfolio_analytics'],
          items: [
            {
              label: 'Concentration',
              routerLink: ['pm/concentration'],
            },
            {
              label: 'Analytics',
              routerLink: ['pm/portfolio-analytics'],
            },
            {
              label: 'Benchmarks',
              routerLink: ['pm/benchmarks'],
            },
          ],
        },
        {
          label: 'Receivable ACH',
          routerLink: ['transactions/receivableach'],
          permissions: ['menu_pm_receivableach'],
        },
        {
          label: 'Collateral',
          permissions: ['menu_pm', 'menu_pm_collateralized_provider_view'],
          items: [
            {
              label: 'Portfolio',
              routerLink: ['pm/collateralportfolio'],
              permissions: ['menu_pm_collateralportfolio'],
            },
            {
              label: 'Provider View',
              routerLink: ['pm/collateralized-provider-view'],
              permissions: ['menu_pm_collateralized_provider_view'],
            },
            {
              label: 'Contribution Positions',
              routerLink: ['pm/collateral-contribution-positions'],
              permissions: ['menu_pm'],
            },
            {
              label: 'Base Point',
              routerLink: ['pm/collateral-base-point'],
              permissions: ['menu_pm_collateral_base_point'],
            },
          ],
        },
        {
          label: 'Investor Trans Projection',
          routerLink: ['pm/investortransprojection'],
          permissions: ['menu_pm_investortransprojection'],
        },
        {
          label: 'Portfolio Overview',
          routerLink: ['pm/portfolio-overview'],
          permissions: ['menu_pm_portfolio_overview'],
        },
        {
          label: 'Time In Status',
          routerLink: ['pm/time-in-status'],
          permissions: ['menu_pm_time_in_status'],
        },
        {
          label: 'Completed Deals',
          routerLink: ['pm/completed-deals'],
          permissions: ['menu_pm_completed_deals'],
        },
        {
          label: 'Defaults',
          routerLink: ['pm/defaults'],
          permissions: ['menu_pm_defaults'],
        },
        {
          label: 'Docs',
          routerLink: ['pm/docs'],
          permissions: ['menu_pm_docs'],
        },
        {
          label: 'Payment Curve',
          routerLink: ['pm/payment-curve'],
          permissions: ['menu_pm_payment_curve'],
        },
        {
          label: 'MCA Transfer Account',
          routerLink: ['pm/transfer-account'],
          permissions: ['menu_pm_transfer_account'],
        },
        {
          label: 'Email Reports',
          permissions: ['menu_pm_email_reports'],
          items: [
            {
              label: 'Collection',
              items: [
                {
                  label: 'Collection - Past Due',
                  routerLink: ['reports/email/collection-past-due'],
                },
                { label: 'Collection - Resuming', routerLink: ['reports/email/collection-resuming'] },
                { label: 'Collection - Modified', routerLink: ['reports/email/collection-modified'] },
                {
                  label: 'Assignment change',
                  routerLink: ['reports/email/assignment-change'],
                  permissions: ['menu_pm_collection_assignment_change'],
                },
              ],
            },
            // { label: 'Collection Payments', routerLink: ['reports/email/collection-payments'] },
            // { label: 'Returned Deposit Withdrawal', routerLink: ['reports/email/returned-deposit-withdrawal'] },
            // { label: 'Daily Fee', routerLink: ['reports/email/daily-fee'] },
            { label: 'Funded', routerLink: ['reports/email/funded'] },
            { label: 'DBA Returned Payments', routerLink: ['reports/email/dba-returned-payments'] },
            { label: 'Rejected Transactions', routerLink: ['reports/email/rejected-transactions'] },
          ],
        },
        {
          label: 'ISO Info',
          routerLink: ['pm/iso-info'],
          permissions: ['menu_pm_iso_info'],
        },
        {
          label: 'Pre-Approved Offers',
          routerLink: ['pm/pre-approved-offers'],
          permissions: ['menu_pm_pre_approved_offers'],
        },
        { label: 'Deal Rate Change', routerLink: ['pm/deal-rate-change'], permissions: ['menu_pm_deal_rate_change'] },
      ] as HeaderMenuItem[],
    },
    {
      label: 'Investor',
      routerLink: ['lender'],
      permissions: ['menu_lender'],
    },
    {
      label: 'ISO active offers',
      routerLink: ['iso-active-offers'],
      permissions: ['menu_iso_active_offers'],
    },
    {
      label: 'ISO Relation',
      routerLink: ['isorelation'],
      permissions: ['menu_isorelation'],
    },
    {
      label: 'Transactions',
      styleClass: 'submenu-wide',
      permissions: ['menu_transactions'],
      items: [
        {
          label: 'New Transaction',
          routerLink: ['transactions', 'new', 0],
          permissions: ['menu_transactions_advanced'],
        },
        { label: 'Transaction List', routerLink: ['transactions'], permissions: ['menu_transactions_advanced'] },
        {
          label: 'Last Incr Commissions',
          routerLink: ['transactions/lastincrementcommissions'],
          permissions: ['menu_transactions_advanced'],
        },
        {
          label: 'ISO/Syndicator Withdrawals',
          routerLink: ['reports/pm/usertypetransactions'],
          permissions: ['menu_transactions_advanced'],
        },
        { label: 'User Deposit Release', routerLink: ['reports/pm/userdepositrelease'], permissions: ['menu_transactions_advanced'] },
        { label: 'MCA Increments', routerLink: ['reports/pm/mcaincrements'], permissions: ['menu_transactions_advanced'] },
        {
          label: 'Expected MCA Transactions',
          routerLink: ['pm/expectedmcatransactions'],
          permissions: ['menu_transactions_advanced'],
        },
        { label: 'Balance Mismatch', routerLink: ['reports/pm/balancemismatch'], permissions: ['menu_transactions_advanced'] },
        { label: 'ISO Commission Status', routerLink: ['reports/pm/commissionstatus'], permissions: ['menu_transactions_advanced'] },
        {
          label: 'ISO Commission Mismatch',
          routerLink: ['reports/pm/commissionmismatch'],
          permissions: ['menu_transactions_advanced'],
        },
        { label: 'UCC Filing', routerLink: ['reports/pm/uccfilingreport'], permissions: ['menu_transactions_advanced'] },
        {
          label: 'Action request',
          permissions: ['menu_transactions_advanced'],
          items: [
            { label: 'Send ACH...', command: () => this.sysService.achScheduled() },
            { label: 'Allocate Transactions...', command: () => this.sysService.allocateTransactions() },
            { label: 'BasePoint Allocate...', command: () => this.sysService.allocateBPAllocate() },
          ],
        },
        { label: 'Receivable ACH', routerLink: ['transactions/receivableach'], permissions: ['menu_transactions_advanced'] },
        {
          label: 'Create Commission Invoice',
          routerLink: ['transactions/createcommissioninvoice'],
          permissions: ['menu_commission_invoice'],
        },
        {
          label: 'Aggregation',
          routerLink: ['transactions/aggregation'],
          permissions: ['menu_aggregation'],
        },
        {
          label: 'Quick Books',
          permissions: ['menu_quickbooks_reports'],
          items: [
            {
              label: 'Investor Credit',
              routerLink: ['transactions/quick-books/investor-credit'],
            },
            {
              label: 'Internal Transfers',
              routerLink: ['transactions/quick-books/internal-transfers'],
            },
            {
              label: 'Non ACH Search',
              routerLink: ['transactions/quick-books/non-ach-search'],
            },
            { label: 'ACH Search', routerLink: ['transactions/achsearch'], permissions: ['menu_transactions_advanced'] },
            {
              label: 'Attorney Fees',
              routerLink: ['transactions/default-fees'],
              permissions: ['inv_default_fees'],
            },
          ],
        },
      ] as HeaderMenuItem[],
    },
    {
      label: 'Collection',
      permissions: ['menu_collection'],
      items: [
        { label: 'Collection', routerLink: ['collection'], permissions: ['menu_collection'] },
        {
          label: 'Portfolio',
          routerLink: ['pm/portfolio'],
          permissions: ['menu_pm_portfolio'],
        },
        {
          label: 'Modify Payment Schedule Request',
          routerLink: ['collection/modifypaymentschedulerequest'],
        },
        {
          label: 'Generate Schedule Request',
          routerLink: ['collection/generateschedulerequest'],
        },
        {
          label: 'Reminders',
          routerLink: ['collection/reminders'],
        },
        {
          label: 'Settlement Reports',
          items: [
            { label: 'Detail', routerLink: ['collection/settlement-reports/detail'] },
            { label: 'Rate Switch', routerLink: ['collection/settlement-reports/rate-switch'] },
            { label: 'Agent Statistics', routerLink: ['collection/settlement-reports/agent-statistics'] },
            { label: 'Not Paying Deals', routerLink: ['collection/settlement-reports/not-paying'] },
            { label: 'Never Paid Deals', routerLink: ['collection/settlement-reports/never-paid'] },
          ],
        },
        {
          label: 'Account Reconciliation',
          routerLink: ['collection/reconciliation'],
        },
        {
          label: 'Base Point Delinquent',
          permissions: ['collection_base_point_delinquent'],
          routerLink: ['pm/collateral-base-point/collection-base-point-delinquent'],
        },
      ] as HeaderMenuItem[],
    },
    {
      label: 'External CPA',
      permissions: ['menu_external_cpa'],
      items: [
        { label: 'Funding Report', routerLink: ['reports/ext-cpa/fundingreport'] },
        { label: 'Payment Report', routerLink: ['reports/ext-cpa/paymentreport'] },
        { label: 'Increment Report', routerLink: ['reports/ext-cpa/incrementreport'] },
        { label: 'Syndication Report', routerLink: ['reports/ext-cpa/syndicationreport'] },
        { label: 'Syndication Payoff Report', routerLink: ['reports/ext-cpa/syndicationpayoffreport'] },
        { label: 'Syndication Increment Report', routerLink: ['reports/ext-cpa/syndicationincrementreport'] },
        { label: 'User Bank Transactions', routerLink: ['reports/ext-cpa/userbanktransactions'] },
        { label: 'Commissions Report', routerLink: ['reports/ext-cpa/commissionsreport'] },
        { label: 'ISO Increment Report', routerLink: ['reports/ext-cpa/commissionuserincrreport'] },
        { label: 'Transactions', routerLink: ['reports/ext-cpa/transactions'] },
      ],
    },
    {
      label: 'Reference Data',
      permissions: ['menu_ref_data', 'menu_ref_users', 'menu_ref_ar'],
      styleClass: 'submenu-right',
      items: [
        {
          label: 'Common',
          styleClass: 'h5 text-primary text-end border-bottom menu-section-header',
          disabled: true,
          permissions: ['menu_ref_data'],
        },
        { label: 'Users', routerLink: ['references/users'], permissions: ['menu_ref_data', 'menu_ref_users'] },
        { label: 'User Roles', routerLink: ['references/users/roles'], permissions: ['menu_ref_data'] },
        { label: 'Permissions', routerLink: ['references/users/permissions'], permissions: ['menu_ref_data'] },
        { label: 'Business Types', routerLink: ['references/businesstypes'], permissions: ['menu_ref_data'] },
        { label: 'Holidays', routerLink: ['references/holidays'], permissions: ['menu_ref_data'] },
        { label: 'DOC Category', routerLink: ['references/doc/category'], permissions: ['menu_ref_data'] },
        { label: 'Transaction Subtypes', routerLink: ['references/transsubtype'], permissions: ['menu_ref_data'] },
        { label: 'Business Attributes', routerLink: ['references/attributes'], permissions: ['menu_ref_data'] },
        { label: 'Account Receivables', routerLink: ['references/ar'], permissions: ['menu_ref_data', 'menu_ref_ar'] },
        {
          label: 'MCA',
          styleClass: 'h5 text-primary text-end border-bottom menu-section-header',
          disabled: true,
          permissions: ['menu_ref_data'],
        },
        { label: 'Funding Programs', routerLink: ['references/mca/fundingprogramtemplate'], permissions: ['menu_ref_data'] },
        { label: 'Statuses', routerLink: ['references/statuses'], permissions: ['menu_ref_data'] },
        { label: 'Status Reason', routerLink: ['references/statuses/reason'], permissions: ['menu_ref_data'] },
        { label: 'Owner Title', routerLink: ['references/ownertitle'], permissions: ['menu_ref_data'] },
        { label: 'Q&A', routerLink: ['references/qa/nda'], permissions: ['menu_ref_data'] },
        { label: 'Funding Call Q&A', routerLink: ['references/questions/funding-call'], permissions: ['menu_ref_data'] },
        { label: 'MCA WF Tasks', routerLink: ['references/mca/wftasks'], permissions: ['menu_ref_data'] },
        { label: 'MCA WF Task Statuses', routerLink: ['references/mca/wftasks/statuses'], permissions: ['menu_ref_data'] },
        { label: 'Rate/Term Map', routerLink: ['references/ratetermmap'], permissions: ['menu_ref_data'] },
        { label: 'Industry', routerLink: ['references/industry'], permissions: ['menu_ref_data'] },
        { label: 'Industry Categories', routerLink: ['references/industry/category'], permissions: ['menu_ref_data'] },
        { label: 'Email Config', routerLink: ['references/emailconfig'], permissions: ['menu_ref_data'] },
      ] as HeaderMenuItem[],
    },
    {
      label: 'System',
      permissions: ['menu_ref_data'],
      styleClass: 'submenu-right',
      keepNested: true,
      items: [
        { label: 'Log', routerLink: ['system/logs'] },
        { label: 'Emails', routerLink: ['system/emails'] },
        { label: 'Clear Cache...', command: () => this.sysService.clearCache() },
        { label: 'Portfolio Snapshot...', command: () => this.sysService.portfolioSnapp() },
        { label: 'MCA Access', routerLink: ['system/mcaaccess'] },
        { label: 'Login activity', routerLink: ['system/loginactivity'] },
        { label: 'Configuration', routerLink: ['system/configuration'] },
        {
          label: 'Change User Log',
          routerLink: ['system/changelog-aggreated'],
          queryParams: { recType: ['person', 'login', 'userdefaults', 'bankacount', 'user_account_receivable'] },
        },
      ],
    },
    {
      label: '',
      icon: 'fa fa-bell-o',
      styleClass: 'p-no-submenu-icon p-menu-badged-lable submenu-right ms-auto',
      keepNested: true,
      routerLink: ['system/live-messages'],
    },
    {
      icon: 'fa fa-bars',
      styleClass: 'p-no-submenu-icon submenu-right',
      keepNested: true,
      items: [
        { label: 'My Profile', routerLink: ['user'], queryParams: { op: 'edit' }, icon: 'fa fa-cogs' },
        { label: 'Change Password', routerLink: ['user/changepwd'], icon: 'fa fa-lock' },
        { label: 'Logout', command: () => this.logout(), icon: 'font-danger fa fa-power-off', styleClass: 'font-danger' },
      ],
    },
  ];

  constructor() {
    super();
    this.connect(
      'loaded',
      combineLatest([
        this.authService.currentUser$.pipe(filter(value => !!value)), // update items when user updated
        this.liveMessageService.newMessageData$.pipe(startWith({} as LiveMessageNew)),
      ]).pipe(
        tap(([, newMessageData]) => {
          this.updateLiveMessageItem(newMessageData);
          this.menuAccessService.setMenuItems(this.menuItems);
        }),
        map(() => true),
      ),
    );
  }

  getMenuItems() {
    const allowedItems = this.menuAccessService.getAllowedItems();
    // flat nested menus when there are not many child items
    return deepFlatWhenTotalLessThan(allowedItems, 'items', 12, 'keepNested');
  }

  private updateLiveMessageItem(messageData: LiveMessageNew) {
    const item = this.menuItems.find(v => v.routerLink?.[0] === 'system/live-messages');
    if (item) {
      item.label = (messageData.total || '').toString();
      item.styleClass = item.label
        ? 'p-no-submenu-icon p-menu-badged-lable submenu-right ms-auto pay-attention'
        : 'p-no-submenu-icon p-menu-badged-lable submenu-right ms-auto';
    }
  }

  private logout(): void {
    this.authService.logout();
  }
}
