// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { Venues } from './environment-constants';

export const httpBase = 'https://businessadvanceportalapi.com:443/';

export const environment = {
  production: true,
  apiUrl: httpBase + 'api/',
  apiRefUrl: httpBase + 'api/',
  mcaUploadUrl: httpBase + 'api/mca/upload/',
  websocketUrl: 'wss://z74wkka464.execute-api.us-west-2.amazonaws.com/gfeny',
  fundingVenues: [Venues.Lacent, Venues.EHC, Venues.UnitedFirst],
  cognitoConfig: {
    userPoolId: 'us-west-2_jFjHA0TcY',
    userPoolClientId: '2cq8360m8ui9gan2b8eik5ldf9',
  },
};

export const leadEnv = {
  apiUrl: httpBase + '/api/',
};

export const appTitle = 'GFENY';
